<template>
  <div>

    <b-row class="kb-search-content-info match-height">
      <b-col cols="12">
        <b-card
          no-body
          class="pdf-preview-card"
        >
          <b-card-body>
            <div
              v-for="item in filteredKB"
              :key="item.id"
              style="margin-bottom: 8px"
            >
              <a
                :href="item.src"
                target="_blank"
              >
                <feather-icon
                  icon="YoutubeIcon"
                  size="20"
                />
                {{ item.title }}
              </a>
              <br>
            </div>
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>

  </div>
</template>

<script>
import {
  BCard, BCardBody, BCol,
  BRow,
} from 'bootstrap-vue'
import FeatherIcon from '@core/components/feather-icon/FeatherIcon.vue'

export default {
  components: {
    BCol,
    BCard,
    BCardBody,
    BRow,
    FeatherIcon,
  },
  data() {
    return {
      knowledgeBaseSearchQuery: '',
      kb: [],
      data: [
        {
          title: 'How to set up your TrafficManager platform',
          description: 'Step by step guide on how to configure the TrafficManager tracking platform as a white-label on your domain.',
          width: 550,
          height: 315,
          src: 'https://www.youtube.com/watch?v=mrRfiAPNBxI&ab_channel=TrafficManager',
          type_platform: 'clients',
        },
        {
          title: 'Overall Presentation of Our Tracking Platform',
          description: 'This is an overall presentation of the TrafficManager affiliate tracking software main features and functionalities. In this video, you will see how the TrafficManager affiliate tracking platform can help you set up your own affiliate program or affiliate network. ',
          width: 550,
          height: 315,
          src: 'https://www.youtube.com/watch?v=Gafp33Rf_G8&ab_channel=TrafficManager',
          type_platform: 'sass',
        },
        {
          title: 'Advance platform settings, logs and the affiliate manager feature',
          description: 'Let\'s take a better look at the TrafficManager advance settings, clicks and conversion logs section, and the affiliate manager features. In this video, you will learn how to do all the initial setup of your tracking platform, how to look into the clicks and conversion logs, and how to use the affiliate manager feature.',
          width: 550,
          height: 315,
          src: 'https://www.youtube.com/watch?v=2-E3JmXjv2o&ab_channel=TrafficManager',
          type_platform: 'sass',
        },
        {
          title: 'The Marketplace section - How to create the first offer',
          description: 'The TrafficManager Marketplace section explained and how to create the first offer in your affiliate network.',
          width: 550,
          height: 315,
          src: 'https://www.youtube.com/watch?v=TesUScalq9s&ab_channel=TrafficManager',
          type_platform: 'sass',
        },
        {
          title: 'Affiliate payments and invoicing feature',
          description: 'A quick overlook on the affiliate payment and the invoicing tool of the TrafficManager affiliate tracking platform. In this video, we will see the entire flow of the affiliate payment requests and how to create and manage our affiliate network invoices.',
          width: 550,
          height: 315,
          src: 'https://www.youtube.com/watch?v=K9yypDfjvXI&ab_channel=TrafficManager',
          type_platform: 'sass',
        },
        {
          title: 'The Manage Users Section Explained',
          description: 'In this video you will understand how to create and fully manage all the user roles from the TrafficManager tracking platform. You will learn how to manually create new users in your affiliate network like admins, affiliates, affiliate managers, etc., what rights has each user role on the platform, and how to fully manage them.',
          width: 550,
          height: 315,
          src: 'https://www.youtube.com/watch?v=2QxkvdSVBPU&ab_channel=TrafficManager',
          type_platform: 'sass',
        },
        {
          title: 'The Affiliates Dashboard Explained',
          description: 'Here is a short presentation of the affiliates dashboard provided by the TrafficManager tracking software.',
          width: 550,
          height: 315,
          src: 'https://www.youtube.com/watch?v=9wdGiqjbWfY&ab_channel=TrafficManager',
          type_platform: 'sass',
        },
        {
          title: 'Product order form and the Leads Management tool - TrafficManager',
          description: 'How to use the TrafficManager product order form feature (lead collector) and the Leads management tool to create and manage cash on delivery (COD) offers in your affiliate network. This tool can be used to create affiliate offers for any type of product for which you need to collect leads like: online products, Insurance offers, Real Estate, Home Improvement, Utility, Online services, etc.',
          width: 550,
          height: 315,
          src: 'https://www.youtube.com/watch?v=ZkYyIhLWeys&ab_channel=TrafficManager',
          type_platform: 'sass',
        },
        {
          title: 'Custom Affiliate Pre-Agreements Setup & Digital Signature - setup guide',
          description: 'TrafficManager Custom Affiliate Pre-Agreements Setup & Digital Signature - setup guide',
          width: 550,
          height: 315,
          src: 'https://www.youtube.com/watch?v=NMBu3xDmEck&ab_channel=TrafficManager',
          type_platform: 'sass',
        },
        {
          title: "How to Customize Your Platform's Login Registration Page - TrafficManager",
          description: "How to Customize Your Platform's Login Registration Page - TrafficManager",
          width: 550,
          height: 315,
          src: 'https://www.youtube.com/watch?v=0khYIJ6hJ9c&ab_channel=TrafficManager',
          type_platform: 'sass',
        },
        {
          title: 'S2S Postback Integration for Beginners - A Step-by-Step Video Guide by TrafficManager com',
          description: 'S2S Postback Integration for Beginners - A Step-by-Step Video Guide by TrafficManager com',
          width: 550,
          height: 315,
          src: 'https://www.youtube.com/watch?v=jewRa7DyOUI&ab_channel=TrafficManager',
          type_platform: 'sass',
        },
        {
          title: 'How to add a custom private domain in your TrafficManager Platform (affiliate side)',
          description: 'How to add a custom private domain in your TrafficManager Platform (affiliate side)',
          width: 550,
          height: 315,
          src: 'https://www.youtube.com/watch?v=sRVp_a-Rn0o&list=PLD7Gac3WYXDKp6nUCKLRIVlAAgLpzdQlu&index=12&ab_channel=TrafficManager',
          type_platform: 'sass',
        },
        {
          title: 'How to add a custom public domain in your TrafficManager Platform - (admin side)',
          description: 'How to add a custom public domain in your TrafficManager Platform - (admin side)',
          width: 550,
          height: 315,
          src: 'https://www.youtube.com/watch?v=hSeL5B61bZ8&list=PLD7Gac3WYXDKp6nUCKLRIVlAAgLpzdQlu&index=13&ab_channel=TrafficManager',
          type_platform: 'sass',
        },
        {
          title: 'How to create custom affiliate ShortLinks - TrafficManager.com',
          description: 'This innovative tool allows affiliates to create user-friendly tracking URLs for promoting your affiliate program\'s products or services, moving from complex tracking parameters to simple and easy-to-remember URLs enabling seamless and easier tracking. From now on, tracking your sales will be possible even across the most difficult mediums like influencers-generated content, live streams, TV or radio shows, commercials, videos, music, movies, and so on.',
          width: 550,
          height: 315,
          src: 'https://www.youtube.com/watch?v=qesUiUuXTv8&ab_channel=TrafficManager',
          type_platform: 'sass',
        },
        {
          title: 'How to modify a user password from the admin dashboard',
          description: 'How to modify a user password from the admin dashboard',
          width: 550,
          height: 315,
          src: 'https://www.youtube.com/watch?v=mrywhemHOTU',
          type_platform: 'sass',
        },
        {
          title: 'How to add a custom deal or custom payout for an affiliate',
          description: 'How to add a custom deal or custom payout for an affiliate',
          width: 550,
          height: 315,
          src: 'https://www.youtube.com/watch?v=MthVqSg7BWg',
          type_platform: 'sass',
        },
        {
          title: 'Create and manage an affiliate manager account',
          description: 'Create and manage an affiliate manager account',
          width: 550,
          height: 315,
          // src: 'https://www.youtube.com/watch?v=9nYw5edecME&feature=youtu.be',
          src: 'https://www.youtube.com/watch?v=kEwoKpK7lzE',
          type_platform: 'sass',
        },
        {
          title: 'How to edit and delete conversions in TrafficManager',
          description: 'How to edit and delete conversions in TrafficManager',
          width: 550,
          height: 315,
          src: 'https://www.youtube.com/watch?v=3IDQ7Sf21ZA',
          type_platform: 'sass',
        },
        {
          title: 'Invalid Conversions Postbacks Logs and Alerts Management',
          description: 'Invalid Conversions Postbacks Logs and Alerts Management',
          width: 550,
          height: 315,
          src: 'https://www.youtube.com/watch?v=729iWxGJq_M',
          type_platform: 'sass',
        },
        {
          title: 'How to connect META Conversions API with TrafficManager',
          description: 'How to connect META Conversions API with TrafficManager',
          width: 550,
          height: 315,
          src: 'https://www.youtube.com/watch?v=uI_ODn6YQY4',
          type_platform: 'sass',
        },
        {
          title: 'How to automatically import offers and integrate with another TrafficManager platform',
          description: 'How to automatically import offers and integrate with another TrafficManager platform',
          width: 550,
          height: 315,
          src: 'https://www.youtube.com/watch?v=9u3i6bJo_XI',
          type_platform: 'sass',
        },
      ],
    }
  },
  computed: {
    filteredKB() {
      const knowledgeBaseSearchQueryLower = this.knowledgeBaseSearchQuery.toLowerCase()
      return this.data.filter(item => item.title.toLowerCase().includes(knowledgeBaseSearchQueryLower) || item.description.toLowerCase().includes(knowledgeBaseSearchQueryLower))
    },
  },
  methods: {
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-knowledge-base.scss';

iframe {
  width: 100%;
  max-width: 550px;
}

/* iphone 12/13 pro max - android pro max*/
@media only screen and (max-width: 428px)  {
  .mobile-UX{
    width: 100% !important;
  }
  .kb-search-content, .text-center{
    height: fit-content;
    padding:15px;
  }

  .text-center p{
    text-align: justify !important;
  }
}

/* iphone 12/13 pro - android pro*/
@media only screen and (max-width: 415px)  {

}

/* iphone 12/13 pro - android pro*/
@media only screen and (max-width: 395px)  {

}

/* iphone 12/13 pro - android pro*/
@media only screen and (max-width: 376px)  {

}

/* smaller phone */
@media only screen and (max-width: 365px)  {

}
</style>

<style lang="scss" scoped>
.dark-layout a {
  color: #fb50a1 !important;
}
</style>
